.main-container{
    background-color:rgba(235,235,235,0.8);

    text-align: center;
    min-height: 100vh;
}
img{
    height:300px

}
.cuisineName{
    color:black; 


}
.cuisineName:hover{
    color:coral;
    /* transform: scale(1.8); */
    font-size: xx-large;
    transition: ease-in 0.2s;
}