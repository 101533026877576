@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Honk&family=Oswald:wght@200..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
    font-family: "Roboto", sans-serif;
    margin:0;
    color: #333;    
}

body {
    background-image: url("https://i.imgur.com/CoSt2Fd.jpg");
  
}

.navbar {

    background-color: rgba(255, 255, 255, 0.5);
    padding: 20px;
    display: flex;
    align-items: center;
    max-width: 80%;
    margin: 0 auto;
    border-bottom: 1px solid #dddddd;
    border-radius: 10px;
}
.navbar-toggler{
 width: 10%;
}
.navbar h1 {
    color: #333
}

.navbar h1:hover {
    color: white;
    background-color: transparent;
}

.navbar .links {
    margin-left: auto;

}

.navbar a {
    margin-left: 16px;
    text-decoration: none;
    padding: 6px;
    color: black;
}

.navbar a:hover {
    color: white;
    background: rgb(0, 0, 0,0.1);
    border-radius: 5px;
    transition: ease-out 0.2s;

}

.content {
    max-width: 80%;
    margin: 40px auto;
    /* padding: 20px;  */
    min-height: 80vh;


}

.grid {
    display: flex;
    flex-wrap: wrap;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 10px;
    /* padding-left: 20px; */
}
.welcome{
   padding:20px 35%;
}
.blogs-container {
    display: flex;
    flex-wrap: wrap;
    margin-left: 20px;
}

.blog-preview {
    width: 28%;
    height: 350px;
    /* Each blog preview takes 50% of the container width */
    box-sizing: border-box;
    /* Include padding and border in the width calculation */
    padding: 10px;
    margin: 20px;
    /* display: flex; */
    border-radius: 10px;
    /* position: relative; */
    overflow: hidden; 
}
.card-img-top {
    width: 100%;
    height: 350px;
    object-fit: cover; /* Maintain aspect ratio and cover the container */
    border-radius: 10px;
  }
.card-img-container {
    height: 200px; /* Set a fixed height for the image container */
    overflow: hidden; /* Hide any content that overflows the container */
  }
@media (max-width: 768px) {
    .blog-preview {
        width: 100%;
    }
}

.blog-preview:hover {
    box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.3);

}

.blog-preview h2 {

    font-size: 20px;
    color: rgb(18, 18, 18);
    margin-bottom: 8px;
}

.blog-preview a {
    text-decoration: none;

}

/* Create new blog form */

.create {
    width: 100%;
    background: linear-gradient(0, #333, rgb(255, 255, 255));
    display: flex;
    flex-direction: column;
    align-items: center;
    /* min-height: 75vh; */
    padding-top: 10%;
    border-radius: 10px;
    min-height: 100vh;
}

.create form {
    /* background-color:red; */
    padding: 20px;
    /* height: 100vh; */
    width: 100%;
    /* Optionally set the width of the form */
    max-width: 70%;
    min-height: 100%;
    /* Optionally set the maximum width of the form */
    text-align: center;
    /* Center the content horizontally within the form */
}

.create h2 {
    text-align: center;
    /* padding-bottom: 10px; */
}

.create label {
    text-align: left;
    display: block;
}

.create input,
.create textarea,
.create select {
    width: 100%;
    padding: 6px 10px;
    margin: 10px 0;
    border: 1px solid #dddddd;
    padding: 8px;
    border-radius: 10px;
}

.create button {
    width: 50%;
    font-size: large;
    background: black;
    color: white;
    border: 0;
    border-radius: 8px;
    cursor: pointer;
    padding: 5px;
    text-align: center;
    margin-top: 10px;
}

.create button:hover {
    background-color: green;
}

.delete {
    background-color: #f44336;
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 8px;
    cursor: pointer;
    align-items: flex-end;
}

.details {
    margin: 20px 0;
    border-radius: 10px;
    padding: 10px;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    /* display: flex; */
}

.detailImage {
   width: 80%;
   height: 500px;
   margin-left: 10%;
   border-radius: 10px;
}

.title p{
    color: #555555;
}
.detialBody {
    margin-top: 10px;
    margin-bottom: 20px;
    text-indent: 2em;
    color: black;
}

.blog-preview {
    display: flex;

}

.logout {
    margin-right: 20px;
}


/* for logo */
.logo a span
{
    font-family: 'Clicker Script', cursive;
    font-size: 40px;
}
.icon{
    font-size:xx-large ;
}
.textBody{
    resize: vertical;
    min-height: 150px;
    width: 100%;
}

.dropdown-menu{
    display: none;
    /* position: absolute; */
    background-color: rgb(255, 255, 255,0.5);
    z-index: 1;
    box-shadow:  0px 8px 16px 0px rgba(0,0,0,0.1);
    border: none;
    width: 25px;
}
.dropdown-menu li{
    width: 80%;
    color: #333;
    text-decoration: none;
    display: block;
    /* text-align: center; */
    /* margin-right: 30%; */
}
@import url(https://fonts.googleapis.com/css?family=Open+Sans);


.search {
  width: 100%;
  position: relative;
  display: flex;
}

.searchTerm {
  width: 100%;
  border: 3px solid #c2c3c2;
  border-right: none;
  padding: 5px;
  height: 45px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #c2c3c2;
}

.searchTerm:focus{
  color: #202020;
}

.searchButton {
  width: 40px;
  /* height: 36px; */
  border: 1px solid #c2c3c2;
  background: #abc0ab;
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;
}

/*Resize the wrap to see the search bar change!*/
.wrap{
    width: 80%;
    margin-left: 10%;
    margin-top: 20px;
}

.resetButton{
   width: 60px;
   border: none;
   background:rgba(158, 241, 85, 0.2);
   font-size: x-large;
   font-weight: 700px;
   border-radius:50%;
}
.resetButton:hover{

    background-color: white;
    transition: background-color 1s ease;
}
