.number_container {
    background: rgba(222, 222, 222, 0.9);
    border-radius: 20px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}

@media (max-width: 768px) {
    .number_container {
        flex-direction: column;
    }

    .number_container {
        height: 100%;
        width: 100%;
    }
}

.btns {
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    margin-right: 5%;
    text-align: center;
    align-items: center;
}

.item {
    margin: 10px;
    width: 100%;
    padding: 10px;
    font-size: x-large;
}

.right {
    flex: 1;
    border-left: 5px solid black;
    /* Adds a vertical line */
    display: flex;
    justify-content: center;
    /* Centers the content horizontally */
    align-items: center;
    /* Centers the content vertically */
    background: rgb(227, 240, 221);
}

.left {
    flex: 1;
}

.result {
    font-size: 300%;
    text-align: center;
}

.timer {
    font-size: 200%;
}

.btn {
    width: 80%;

}

.btn-1 {
    background: rgb(184, 184, 184);

}

.btn-2 {
    background: rgb(151, 175, 154);
}

.btn-3 {
    background: rgb(132, 181, 125);

}

.btn-4 {
    background: rgb(105, 180, 93);
}