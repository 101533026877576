.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2); /* Adjust the background color and transparency as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure the overlay is on top of other elements */
  }

.fix-center{
    position: fixed;
    bottom: 10%;
    right: 0;
    transform: translate(-50%, -50%);
    z-index: 1000; /* Ensure the element is on top of other elements */
    padding: 10px; /* Add padding for better visibility */
}

.commentForm{
    padding: 2%;
    margin-top: 5%;
    background-color: rgba(255,255,255,0.8);
    border-radius: 10px;
    width:60%;
    
}

.message{
    height: 100px;
}
.commentbtn {
    display: flex;
    justify-content: center; 
    
 }
 .custom-alert {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #f8d7da;
    color: #721c24;
    padding: 20px;
    border-radius: 8px;
    border: 2px solid #f5c6cb;
    z-index: 9999;
  }
  
  .alert-content {
    text-align: center;
  }
  
  .close {
    float: right;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    margin-top: -10px;
  }
  
  .close:hover {
    color: #721c24;
  }
  