@import 
url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;1,500;1,600&display=swap');@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;1,500;1,600;1,700&display=swap');

:root
{
    --bg-black-900:#f2f2fc; 
    --bg-black-100:#fdf9ff; 
    --bg-black-50:#e8dfec;
    --text-black-900:#1d1c24;
    --text-black-700: #504e70;
}
body.dark
{
    --bg-black-900:#151515; 
    --bg-black-100:#222222; 
    --bg-black-50:#d2d2d2;
    --text-black-900:#ffffff;
    --text-black-700: #e9e9e9;
}
body 
{
    margin: 0;
    padding: 0;
    line-height: 1.5; 
    font-size:16px;
    font-family: 'Poppins' sans-serif;
}
*
{
    margin:0; 
    padding: 0;
    outline: none; 
    text-decoration: none;
    box-sizing: border-box; 
}
::before, ::after 
{
    box-sizing: border-box;
}
ul
{
    list-style: none;
}
.section
{
    background-color: var(--bg-black-900);
    min-height: 100vh;
    display:block;
    padding: 0 30px;
    opacity: 1;
    position:fixed; 
    left: 270px;
    top: 0;
    right:0; 
    bottom: 0;
    z-index: 0;
    overflow-y: auto;
    overflow-x: hidden;
    transition: all 0.3s ease;
    /* margin-top: 5%; */
}
.section.back-section
{
    z-index: 1; 
}
.section.active
{
    z-index: 2;
    opacity: 1;
    animation: slideSection 1s ease;
}
@keyframes slideSection
{
    0%
    {
        transform: translateX(100%);
    }
    100%
    {
        transform: translateX(0%);
    }
}
.hidden
{
    display:none !important; 
}

.padd-15
{
    padding-left: 15px;
    padding-right: 15px;
}
.container
{
    max-width: 1100px; 
    width: 100%;
    margin: auto;
}
.section .container
{
    padding-top: 60px; 
    padding-bottom: 70px;

}
.section-title
{
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 60px;
}
.section-title h2
{
    font-size: 40px;
    color: var(--text-black-900);
    font-weight: 700;
    position:relative; 
}
.section-title h2::before
{
    content: '';
    height: 4px;
    width: 50px;
    background-color: orange;
    position: absolute;
    top: 100%;
    left:0; 
}
.section-title h2::after 
{
    content: '';
    height: 4px;
    width: 25px;
    background-color: orange;
    position: absolute;
    top: 100%;
    left:0; 
    margin-top: 8px;
}
.row
{
    display: flex; 
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: 15px; 
    position: relative;
}
.btn
{
    font-size: 16px;
    font-weight: 500;
    padding: 12px 35px;
    /* color:rgb(255, 0, 0); */
    border-radius: 40px;
    border: none;
    display: inline-block;
    white-space: nowarp; 
    /* background: orange; */
    transition: all 0.3s ease;
}
.btn:hover :not(login-btn)
{
    transform: scale(1.5);
}
.shadow-dark
{
    box-shadow: 0 0 20px rgba(48, 46, 77, 0.15);
}
/* aside  */
.aside
{
    /* margin-top: 5%; */
    width: 270px;
    background-color: var(--bg-black-100);
    position: fixed; 
    left:0; 
    top:0; 
    padding: 30px;
    height: 100%;
    border-right: 1px solid var(--bg-black-50);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    transition: all 0.3s ease;
}
.aside .logo
{
    position: absolute; 
    top:50px; 
    font-size: 30px;
    text-transform: capitalize;
}
.aside .logo a
{
    color: var(--text-black-900);
    font-weight: 700;
    font-size:40px;
    padding: 15px 20px;
    letter-spacing: 5px;
    position:relative; 

}
.aside .logo a span
{
    font-family: 'Clicker Script', cursive;
    font-size: 40px;
}
.aside .logo a::before
{
    content: ''; 
    position: absolute; 
    width: 20px; 
    height: 20px;
    border-bottom: 5px solid orange; 
    border-left: 5px solid green; 
    bottom: 0;
    left: 0;
}
.aside .logo a::after
{
    content: ''; 
    position: absolute; 
    width: 20px; 
    height: 20px;
    border-top: 5px solid green;
    border-right: 5px solid orange;
    top: 0;
    right: 0;
}
.aside .nav-toggler 
{
    height: 40px;
    width: 45px; 
    border: 1px solid var(--bg-black-50); 
    cursor: pointer;
    position: fixed; 
    left: 300px; 
    top: 20px; 
    border-radius: 5px;
    background: var(--bg-black-100);  
    display: none;
    justify-content: center; 
    transition: all 0.3s ease;
    
}
.aside .nav-toggler span
{
    height: 2px;
    width: 18px;
    top: 20px;
    background: orange;
    display: inline-block;
    position: relative;
}
.aside .nav-toggler.open span
{
    background-color: transparent;
}
.aside .nav-toggler span::before
{
    content: ' ';
    height: 2px;
    width: 18px;
    background: orange;
    position: absolute;
    top: -6px;
    left: 0;
}
.aside .nav-toggler.open span::before 
{
    transform:rotate(45deg);
    top:0; 
}
.aside .nav-toggler span::after
{
    content: ' ';
    height: 2px;
    width: 18px;
    background: orange;
    position: absolute;
    top: 6px;
    left: 0;
}
.aside .nav-toggler.open span::after 
{
    transform:rotate(-45deg);
    top:0; 
}
.aside .nav
{
    margin-top: 50px;
}
.aside .nav li
{
    margin-bottom: 20px;

    display: block;
}
.item
{
    font-size:18px; 
    font-weight: 600;
    display: block;
    border-bottom: 1px solid var(--bg-black-50);
    color: var(--text-black-900); 
    padding: 5px 15px;
}
.item.active
{
    color: orange; 
}
.aside .nav li a i 
{
margin-right: 20px;
padding-left: 20px;
}
/* home */ 
.home
{
    min-height: 100vh;
    display: flex;
    color: var(--text-black-900);
}
.home .home-info
{
    flex: 0 0 60%;
    max-width: 60%;
}
h3.hello
{
    font-size: 35px;
    margin: 15px 0;
}
h3.hello span
{
    font-family:'Clicker Script', cursive ;
    font-size: 35px;
    font-weight: 900;
    color:rgb(255, 119, 0);
}
h3.my-profession
{
    font-size:30px; 
    margin:15px; 
}
.typing
{
    color:orange;
}
.home-info p
{
    margin-bottom: 70px;
    font-size: 20px;
    color: var(--text-black-700);
}

.home .home-img
{
    flex: 0 0 40%;
    max-width: 40%;
    text-align: center;
    position: relative;
  
}
.home-img::before
{
    content: '';
    position:absolute;
    height: 80px;
    width: 80px;
    right: 20px;
    border-top: 10px solid green;
    border-left: 10px solid orange;
    left: -20px;
    top: -40px;
}
.home-img::after
{
    content: '';
    position:absolute;
    height: 80px;
    width: 80px;
    right: 20px;
    border-bottom: 10px solid  green;
    border-right: 10px solid orange; 
    right: -20px;
    bottom: -40px;
}
.home .home-img img
{
    margin: auto;
    border-radius: 5px;
    position: relative;
    height: 400px;
}
/* About */ 
.about .about-content
{
    flex: 0 0 100%;
    max-width:100%;
}
.about .about-content .about-text
{
    flex: 0 0 100%;
    max-width:100%; 
    
}
.about .about-content .about-text h3
{
    font-size: 24px;
    margin-bottom: 15px;
    font-weight: 700;
    color: var(--text-black-900);
}
.about .about-content .about-text h3 span
{
    color: orange;

}.about .about-content .about-text p
{
    font-size: 16px;
    line-height: 25px;
    color: var(--text-black-700);
}
.about .about-content .personal-info
{
    flex: 0 0 60%;
    max-width: 60%;
    margin-top: 40px;
}
.about .about-content .personal-info .info-item
{
    flex: 0 0 50%; 
    max-width: 50%; 
}
.about .about-content .personal-info .info-item p
{
    font-weight: 600;
    padding: 10px 0;
    font-size: 16px;
    color: var(--text-black-900);
    border-bottom: 1px solid var(--bg-black-50);
}
.about .about-content .personal-info .info-item p span
{
    font-weight: 400;
    color: var(--text-black-700);
    margin-left: 4px;
    display: inline-block; 
}
.about .about-content .personal-info .buttons
{
    margin-top: 30px; 
}
.about .about-content .personal-info .buttons .btn
{
    margin-top: 10px;
}
.about .about-content .skills
{
    flex:  0 0 40%;
    max-width: 40%;
    margin-top: 40px;
}
.about .about-content .skills .skill-item
{
    flex: 0 0 100%; 
    max-width: 100%;
}
.about .about-content .skills .skill-item h5
{
    line-height: 40px;
    font-weight: 600;
    font-size:16px; 
    color: var(--text-black-900);
    text-transform: capitalize;
}
.about .about-content .skills .skill-item .progress
{
    background-color:var(--bg-black-50); 
    height: 7px; 
    border-radius: 4px;
    width:100%;
    position: relative;
}
.about .about-content .skills .skill-item
{
    margin-bottom: 25px;

}
.about .about-content .skills .skill-item .progress .progress-in
{
    position: absolute;
    left:0;
    top: 0;
    height: 100%;
    /* background-color: orange; */
    background:orange;
}
.about .about-content .skills .skill-item .skill-percent
{
    position: absolute;
    right: 0;
    color: black;
    top:-40px;
    font-weight: 400;
    line-height: 40px;
}
.about .about-content .education, 
.about .about-content .experience
{
    flex: 0 0 50%; 
    max-width: 50%;
    margin-top:30px;
}
.about .about-content h3.title{
    font-size: 24px; 
    margin-bottom: 30px;
    font-weight: 700;
    color:var(--text-black-900);
}
.about .about-content .timeline-box
{
    flex: 0 0 100%; 
    max-width: 100%;
}
.about .about-content .timeline
{
    background-color: var(--bg-black-100);
    padding: 30px 15px;
    border: 1px solid var(--bg-black-50);
    border-radius: 10px;
    width: 100%;
    position: relative;
}
.about .about-content .timeline .timeline-item
{
    position: relative;
    padding-left: 37px;
    padding-bottom: 50px;
}
.about .about-content .timeline .timeline-item:last-child
{
    padding-bottom: 0;
}
.about .about-content .timeline .timeline-item::before{
    content: '';
    width:1px;
    position: absolute;
    height: 100%;
    left:7px;
    top:0;
    background-color: orange;
}
.about .about-content .timeline .circle-dot
{
    position:absolute;
    left:0; 
    top: 0;
    height: 15px;
    width:15px;
    border-radius:50px;
    background-color: orange;
}
.about .about-content .timeline .timeline-date
{
    font-weight:400;
    font-size: 14px;
    margin-bottom: 12px;
    color:var(--text-black-700);
}
.about .about-content .timeline .timeline-date .fa
{
    margin-right: 5px;
}
.about .about-content .timeline .timeline-title
{
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 15px;
    text-transform: capitalize;
    color: var(--text-black-900);
}
.about .about-content .timeline .timeline-text
{
    line-height: 25px;
    font-size:16px;
    text-align: justify;
    color:var(--text-black-700);
}
/* Service */
.service .container
{
    padding-bottom: 40px;
}
.service .service-item 
{
    margin-bottom: 30px;
    flex: 0 0 33.33%;
    max-width: 33.33%;
}
.service .service-item .service-item-inner
{
    background-color: var(--bg-black-100);
    border: 1px solid var(--bg-black-50);
    border-radius: 10px;
    padding: 30px 15px;
    text-align: center;
    transition: all 0.35 ease;
    
}
.service .service-item .service-item-inner:hover
{
    box-shadow: 0 0 20px rgba(48, 46, 77,0.15);
}
.service .service-item .service-item-inner .icon
{
    height: 60px;
    width: 60px;
    border-radius: 50%;
    display: block;
    
    margin: 0 auto 30px;
    text-align: center;
    transition: all 0.3s ease;
}
.service .service-item .service-item-inner .icon .fa
{
    font-size: 30px;
    line-height: 60px;
    color: orange; 
    transition: all 0.5s ease;
}
.service .service-item .service-item-inner:hover .icon
{
    background:orange; 
}
.service .service-item .service-item-inner:hover .icon .fa
{
    font-size: 25px;
    color:#ffffff;
}
.service .service-item .service-item-inner h4
{
    font-size: 18px;
    margin-bottom:15px;
    color:var(--text-black-900); 
    font-weight: 700;
    text-transform: capitalize;
}
.service .service-item .service-item-inner p
{
    font-size:16px;
    color: var(--text-black-700);
    line-height: 25px;
}
/* portfolio */
.portfolio .container
{
    padding-bottom: 40px;
}
.portfolio .portfolio-heading
{
    flex: 0 0 100%; 
    max-width: 100%;
    margin-bottom: 40px;
}
.portfolio .portfolio-heading h2
{
    color: var(--text-black-900);
    font-weight: 500;
}
.portfolio .portfolio-item
{
    flex:0 0 50%;
    max-width:50%;
    margin-bottom: 30px;
}
.portfolio .portfolio-item-inner
{
    border:6px solid var(--bg-black-100);
    border-radius: 10px;
    overflow: hidden;
    cursor:pointer; 
}
.portfolio .portfolio-item-inner .portfolio-img img
{
    width:100%;
    display: block;
}
/* contact */
.contact-title
{
    color: orange;
    text-align: center;
    font-size: 25px;
    margin-bottom: 20px;
}
.contact-sub-title
{
    color: var(--text-black-900);
    text-align: center;
    font-size: 15px;
    margin-bottom: 60px;
}
.contact .contact-info-item
{
    flex:0 0 33.33%;
    max-width: 33.33%;
    text-align: center;
    margin-bottom: 60px;
}
.contact .contact-info-item .icon
{
    display: inline-block;
}
.contact .contact-info-item .icon .fa
{
    font-size: 25px;
    color: orange;
}
.contact .contact-info-item h4
{
    font-size: 18px;
    font-weight: 700;
    color: var(--text-black-900);
    text-transform: capitalize;
    margin:15px 0 5px; 
}
.contact .contact-info-item p 
{
    font-size: 16px;
    line-height: 25px;
    color:var(--text-black-700);
    font-weight: 400;
}
.contact .contact-form 
{
    flex: 0 0 100%; 
    max-width: 100%;
}
.contact .contact-form .col-6
{
    flex: 0 0 50%; 
    max-width: 50%;
}
.contact .contact-form .col-12
{
    flex: 0 0 100%; 
    max-width: 100%;
}
.contact .contact-form .form-item
{
    margin-bottom: 30px;
}
.contact .contact-form .form-item .form-control
{
    width: 100%;
    height: 50px;
    border-radius: 25px;
    background: var(--bg-black-100);
    border: 1px solid var(--bg-black-50);
    padding: 10px 25px;
    font-size:16px; 
    color:var(--text-black-700); 
    transition: all 0.3s ease; 
}
.contact .contact-form .form-item .form-control:focus
{
    box-shadow: 0 0 20px rgba(48, 46, 77, 0.15);
}
.contact .contact-form .form-item textarea.form-control
{
    height: 140px;
}
.contact .contact-form .btn
{
    height: 50px;
    padding: 0 50px; 
}
@media (max-width:1199px) 
{
    .section .container
    {
        padding-top:70px; 
    }
    .aside
    {
        left: -270px;
    }
    .aside.open
    {
        left:0; 

    }
    .aside .nav-toggler
    {
        display: flex;
        left: 30px;
    }
    .aside .nav-toggler.open
    {
        left: 300px;
    }
    .section
    {
        left: 0;
    }
    .section.open
    {
        left:270px;
    }
    .about .about-content .personal-info .info-item p span 
    {
        display:block;
        margin-left: 0;
    }
}
@media (max-width:991px) 
{   
    .contact .contact-info-item,
    .portfolio .portfolio-item,
    .service .service-item
    {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .home .home-info
    {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .home .home-img
    {
        display:none; 
    }
}
@media (max-width:767px) 
{   
    .contact .contact-form .col-6,
    .contact .contact-info-item,
    .portfolio .portfolio-item, 
    .service .service-item,
    .about .about-content .experience,
    .about .about-content .education,
    .about .about-content .skills, 
    .about .about-content .personal-info
    {
        flex: 0 0 100%; 
        max-width: 100%;
    }
}