.length {

    height: 50px;
    text-align: center;
    display: flex;
    align-items: center;
}
/* .convert{
    padding-bottom: 10%;
} */
.equal {
    text-align: center;
    font-size: xx-large;
}

.length input {
    background: rgb(194, 215, 221);
    text-align: center;
    padding: 10px;
    border: 2px solid #ddd;
    /* Add a border */
    border-radius: 4px;
    transition: border-color 0.3s ease;
}

.length label {
    flex-grow: 1;
    font-size: x-large;
    /* Allow labels to grow and take available space equally */
}
.row{
    /* background-color: rgba(255,255,255, 0.5);; */
    padding-left: 10px;
    padding-right: 10px;
}
.selectForm{
    /* background-color: rgb(255, 145, 0); */
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 10px;
    height: 80px;
    font-size: x-large;
    padding-bottom: 20px;
}
.selectForm select{
    padding: 10px;
    border: 2px solid #ddd;
    /* Add a border */
    border-radius: 10px;
    transition: border-color 0.3s ease;
}
.units,.chooseForm {
    background-color: rgba(255,255,255, 0.5);
    padding: 10px;
    border-radius: 10px;
    min-width: 450px;
}
