.container {
    /* background: linear-gradient(1500deg, rgb(88, 88, 88) 40%, rgb(255, 255, 255)); */
    color:#333;
    border-radius: 10px;
    min-height: 80vh;
}
.login {
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height:80vh;
  }
.form-label {
    font-size: large;
    font-weight: 500;
    color: aliceblue;
    padding:10px 0;

}
.loginForm {
    margin-top: 10%;
    width: 50%;
}


button {
    width: 100%;
   
}

.button-container {
    text-align: center;
    padding-top: 20px;
  }