.foodMenu {

    border-radius: 10px;
    display: flex;
    justify-content: center;
    background-color:rgba(255,255,255,0.8);
    padding: 10px;
}
.circular--landscape {
    display: inline-block;
    position: relative;
    width: 200px;
    height: 200px;
    overflow: hidden;
    border-radius: 50%;
}

.circular--landscape img {
    width: auto;
    height: 100%;
    margin-left: -50px;
}
.randomChoose{
  background-color:rgba(255,255,255,0.7);
  padding: 10px;
  border-radius: 10px;

}
.selectFood{
    color: rgb(255, 0, 0);
    /* background:white; */
    border-radius: 10px;
    width: 5;
    font-size: xxx-large;
    text-decoration: none;
}
.selectFood:hover{
    text-decoration: underline;
}
small{
    text-decoration: underline;
}