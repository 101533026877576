.style-switcher
{
    position: fixed;
    right: 0;
    top:60px; 
    padding: 15px;
    width: 200px;
    border: 1px solid var(--bg-black-50);
    background: var(--bg-black-100);
    z-index: 101;
    border-radius: 5px;
    transition: all 0.3s ease;
    transform: translateX(100%);
}
.style-switcher.open
{
    transform: translateX(-25px);
}
.style-switcher .s-icon
{
    position:absolute;
    height: 40px;
    width: 40px;
    text-align: center;
    font-size: 20px;
    background:var(--bg-black-100); 
    color: var(--text-black-900);
    right: 100%;
    border: 1px solid var(--bg-black-50); 
    margin-right: 25px;
    cursor:pointer;
    transition: all 0.3s ease; 
    border-radius: 50%;
}
.style-switcher .s-icon
{
    line-height: 40px;
}
.style-switcher .style-switcher-toggler
{
    top:0;
}
.style-switcher .day-night
{
    top:0;
    background: #fff;
}
.style-switcher h4
{
    margin: 0 0 10px;
    color: var(--text-black-700);
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
}
.style-switcher .colors
{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.style-switcher .colors span
{
    display: inline-block;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: 1px solid red;
}
.style-switcher .color-1
{
    background: #FF8530;
}
.style-switcher .color-2
{
    background:#55a5e7;
}
.style-switcher .color-3
{
    background: #c496e0;
}
.style-switcher .color-4
{
    background: #697E3C;
}
.style-switcher .color-5
{
    background: #8a8a8a;
}